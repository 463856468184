<template>
  <div class="roleList">
    <h1>角色、菜单、权限管理</h1>
    <el-form :inline="true">
      <el-form-item>
        <el-button-group>
          <el-button @click="commitFindList()">刷新</el-button>
          <el-button type="primary" @click="add()">新增</el-button>
          <el-button type="warning" :disabled="pageState.selected.length != 1" @click="update()">详情</el-button>
          <el-button type="danger" :disabled="pageState.selected.length != 1" @click="del()">删除</el-button>
        </el-button-group>
      </el-form-item>
    </el-form>
    <el-table :data="data.roleList" @selection-change="selectionChange()" ref="tabRef" border style="height: calc(100vh - 310px);">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column prop="id" label="ID" width="100" />
      <el-table-column prop="name" label="角色" width="200">
        <template #default="scope">
          <el-link @click="update(scope.row)">{{ scope.row.name }}</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="menuList" label="菜单（具体菜单请点击详情查看）" show-overflow-tooltip>
        <template #default="scope">
          <el-text size="small" v-for="menu in scope.row.menuList" :key="menu">{{ menu + ' ' }}</el-text>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage, ElMessageBox } from 'element-plus'
import api from '../../apis/api'
import cache from '../../utils/cache'

const router = useRouter()
const tabRef = ref()

const data = reactive({
  roleList: [],
  menuList: [],
})

const pageState = reactive({
  selected: []
})

const commitFindList = () => {
  api.get('/backend/role/getAllRole').then(res => {
    data.roleList = res.roleList
  })
}
commitFindList()

const selectionChange = () => {
  pageState.selected = tabRef.value.getSelectionRows()
}

const add = () => {
  cache.setObject(cache.keys.pageParam + 'roleDetail', null)
  router.push('/roleDetail')
}

const update = (role) => {
  if (!role) {
    role = tabRef.value.getSelectionRows()[0]
  }
  cache.setObject(cache.keys.pageParam + 'roleDetail', role)
  router.push('/roleDetail')
}

const del = () => {
  const role = tabRef.value.getSelectionRows()[0]
  ElMessageBox.confirm('', {
    title: '确认删除：',
    message: '将要删除 ' + role.name + ' 。',
    autofocus: false,
    confirmButtonText: '删除',
    cancelButtonText: '取消'
  }).then(() => {
    api.post('/backend/role/delById', { id: role.id }).then(() => {
      ElMessage.success('删除成功')
      commitFindList()
    })
  })
}

</script>

<style lang="less"></style>